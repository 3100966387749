/**
 * Screen Breakpoints
 */

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-sm-min: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-md-min: $screen-md;

// Large screen / wide desktop
$screen-lg: 992px;
$screen-lg-min: $screen-lg;

// Extra large screen / full hd
$screen-xl: 1200px;
$screen-xl-min: $screen-xl;

// Extra extra large screen / large desktop
$screen-xxl: 1600px;
$screen-xxl-min: $screen-xxl;

// And the Maximums
$screen-xs-max: ($screen-sm-min - 1px);
$screen-sm-max: ($screen-md-min - 1px);
$screen-md-max: ($screen-lg-min - 1px);
$screen-lg-max: ($screen-xl-min - 1px);
$screen-xl-max: ($screen-xxl-min - 1px);

@mixin xs($only: true) {
  @if $only {
    @media (max-width: $screen-xs-max) {
      @content;
    }
  } @else {
    @media only screen {
      @content;
    }
  }
}

@mixin sm($only: false) {
  @if $only {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
      @content;
    }
  } @else {
    @media (min-width: $screen-sm-min) {
      @content;
    }
  }
}

@mixin md($only: false) {
  @if $only {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
      @content;
    }
  } @else {
    @media (min-width: $screen-md-min) {
      @content;
    }
  }
}

@mixin lg($only: false) {
  @if $only {
    @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
      @content;
    }
  } @else {
    @media (min-width: $screen-lg-min) {
      @content;
    }
  }
}

@mixin xl($only: false) {
  @if $only {
    @media (min-width: $screen-xl-min) and (max-width: $screen-xl-max) {
      @content;
    }
  } @else {
    @media (min-width: $screen-xl-min) {
      @content;
    }
  }
}

@mixin xxl() {
  @media (min-width: $screen-xxl-min) {
    @content;
  }
}

@mixin transition(
  $properties: 'all',
  $duration: 300ms,
  $timing-function: 'ease',
  $delay: 0
) {
  transition-property: $properties;
  transition-duration: $duration;
  transition-timing-function: $timing-function;
  transition-delay: $delay;
}