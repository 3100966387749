// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

:root {
  /**
   * Colours 🎨
   */

  --ion-color-primary: #00602e;
  --ion-color-primary-rgb: 0, 96, 46;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #005428;
  --ion-color-primary-tint: #1a7043;

  --ion-color-secondary: #1f3686;
  --ion-color-secondary-rgb: 31, 54, 134;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #1b3076;
  --ion-color-secondary-tint: #354a92;

  --ion-color-tertiary: #00a2d2;
  --ion-color-tertiary-rgb: 0, 162, 210;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #008fb9;
  --ion-color-tertiary-tint: #1aabd7;

  --ion-color-success: #00af35;
  --ion-color-success-rgb: 0, 175, 53;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #009a2f;
  --ion-color-success-tint: #1ab749;

  --ion-color-warning: #f5b841;
  --ion-color-warning-rgb: 245, 184, 65;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d8a239;
  --ion-color-warning-tint: #f6bf54;

  --ion-color-danger: #ff4013;
  --ion-color-danger-rgb: 255, 64, 19;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e03811;
  --ion-color-danger-tint: #ff532b;

  --ion-color-dark: #333333;
  --ion-color-dark-rgb: 51, 51, 51;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #2d2d2d;
  --ion-color-dark-tint: #474747;

  --ion-color-medium: #777777;
  --ion-color-medium-rgb: 119, 119, 119;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #696969;
  --ion-color-medium-tint: #858585;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /**
   * Fonts 🎉
   */
  --ion-font-family: 'Lato Bold', sans-serif;

  --app-background: #ffffff;
  --app-background-shade: var(--ion-background-color-step-50, #f2f2f2);
  --app-background-alt: var(--ion-color-primary);
  --app-background-alt-shade: var(--ion-color-primary-shade);

  --app-narrow-margin: 12px;
  --app-fair-margin: 16px;
  --app-broad-margin: 20px;

  --app-narrow-radius: 8px;
  --app-fair-radius: 8px;
  --app-broad-radius: 8px;
}

html.ios {
  --app-header-height: 44px;
}

html.md {
  --app-header-height: 56px;
}
